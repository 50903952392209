<template>
  <div>
    List Users
    <br>
    Add User
    <br>
    List Clients
    <br>
    Delete Client
  </div>
</template>

<script>
  export default {
    // TODO: Admin area
    name: "Admin",
    data() {
      return {

      }
    },
    computed: {

    },
    methods: {
      
    }
  }
</script>